import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faIdCard, faFilter, faEarthAmerica, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import MultiSelect from 'components/MultiSelect'
import Search from 'components/Search'
import Select from 'components/Select'
import Toggle from 'components/Toggle'

import { COMPANY_SIZES_MIN, COMPANY_SIZES_MAX, COMPANY_TYPES, LANGUAGES, STATUS, TENURE_MIN, TENURE_MAX, PROGRESS } from 'constants/filters'

const Filters = ({ allCompanies, companyLists, handleChangeFilters, newFilters, type }) => {
  const { t } = useTranslation('global')
  const [visibleFilters, setVisibleFilters] = useState([0, 1, 2, 3])
  const [companies, setCompanies] = useState(allCompanies || [])

  const handleChangeVisibleFilters = (key) => {
    setVisibleFilters((prevFilters) => {
      if (prevFilters.includes(key)) {
        return prevFilters.filter((filterKey) => filterKey !== key)
      } else {
        return [...prevFilters, key]
      }
    })
  }

  const hasValue = (key) => {
    return Boolean(newFilters[key])
  }

  const handleFetchCompanies = () => {
    apiClient.get(buildApiUrl(`/companies`))
      .then((response) => {
        const list = response.data.map(c => {
          return {text: c.name, value: c.id}
        })
        setCompanies(list)
      })
  }

  useEffect(() => {
    if(['people', 'companies'].includes(type) && !allCompanies) {
      handleFetchCompanies()
    }
  }, [type])

  return (
    <div className='filters'>
      {type === 'people' && (
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(0)}>
            <div>
              <FontAwesomeIcon icon={faFilter} />
              <span>{t('general_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(0) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(0) ? 'filters__list' : 'filters__list closed'}>
            <div className='filters__list__item'>
              <span>{t('status')}</span>
              <Select
                choices={STATUS}
                handleChangeFilters={handleChangeFilters}
                item='status'
                newValue={newFilters.status}
                placeholder={t('ex_connected')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('progress')}</span>
              <div className='filters__list__item__with-two-selects'>
                <Select
                  choices={PROGRESS}
                  handleChangeFilters={handleChangeFilters}
                  item='progress_min'
                  newValue={newFilters.progress_min}
                  placeholder={t("min")}
                />
                <Select
                  choices={PROGRESS}
                  handleChangeFilters={handleChangeFilters}
                  item='progress_max'
                  newValue={newFilters.progress_max}
                  placeholder={t("max")}
                />
              </div>
            </div>
            <div className='filters__list__item'>
              <span>{t('has_validated_email')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='is_validated_email' newValue={newFilters.is_validated_email} />
            </div>
            <div className='filters__list__item'>
              <span>{t('has_validated_phone')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='is_validated_phone' newValue={newFilters.is_validated_phone} />
            </div>
            <div className='filters__list__item'>
              <span>{t('has_linkedin_profile')}</span>
              <Toggle handleChangeFilters={handleChangeFilters} item='has_linkedin_profile' newValue={newFilters.has_linkedin_profile} />
            </div>
          </div>
        </div>
      )}

      {['people'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(1)}>
            <div>
              <FontAwesomeIcon icon={faIdCard} />
              <span>{t('contacts_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(1) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(1) ? 'filters__list' : 'filters__list closed'}>
            <div className='filters__list__item'>
              <span>{t('full_name')}</span>
              <Search
                handleChangeFilters={handleChangeFilters}
                item='full_name'
                newValue={newFilters.full_name}
                placeholder={t('john_doe')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('job_title')}</span>
              <Search
                handleChangeFilters={handleChangeFilters}
                item='job_title'
                newValue={newFilters.job_title}
                placeholder={t('ex_accountant')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('time_in_position')}</span>
              <div className='filters__list__item__with-two-selects'>
                <Select
                  choices={TENURE_MIN}
                  handleChangeFilters={handleChangeFilters}
                  item='tenure_at_position_min'
                  newValue={newFilters.tenure_at_position_min}
                  placeholder={t("min")}
                />
                <Select
                  choices={TENURE_MAX}
                  handleChangeFilters={handleChangeFilters}
                  item='tenure_at_position_max'
                  newValue={newFilters.tenure_at_position_max}
                  placeholder={t("max")}
                />
              </div>
            </div>
            <div className='filters__list__item'>
              <span>{t('country')}</span>
              <MultiSelect
                item='country'
                handleChangeFilters={handleChangeFilters}
                newValue={newFilters.country}
                placeholder={t('ex_north_america')}
              />
            </div>
            <div className='filters__list__item'>
              <span>{t('language')}</span>
              <MultiSelect
                choices={LANGUAGES}
                item='language'
                handleChangeFilters={handleChangeFilters}
                newValue={newFilters.language}
                placeholder={t('ex_english')}
              />
            </div>
          </div>
        </div>
      }
      
      {['companies', 'people'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(2)}>
            <div>
              <FontAwesomeIcon icon={faEarthAmerica} />
              <span>{t('company_informations')}</span>
            </div>
            <div
              className={visibleFilters.includes(2) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(2) ? 'filters__list' : 'filters__list closed'}>
            {['people', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company')}</span>
                <MultiSelect
                  choices={companies}
                  handleChangeFilters={handleChangeFilters}
                  item='company_id'
                  newValue={newFilters.company_id}
                />
              </div>
            }
            {['people'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('Companies_lists')}</span>
                <Select
                  choices={companyLists}
                  handleChangeFilters={handleChangeFilters}
                  isDisabled={hasValue('companyName') || hasValue('companyId') || hasValue('companySizeMin') || hasValue('companySizeMax')}
                  item='company_list_id'
                  newValue={newFilters.company_list_id}
                />
              </div>
            }
            {['searchCompanies', 'people', 'searchPeople', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_size')}</span>
                <div className='filters__list__item__with-two-selects'>
                  <Select
                    choices={COMPANY_SIZES_MIN}
                    handleChangeFilters={handleChangeFilters}
                    item='company_size_min'
                    newValue={newFilters.company_size_min}
                    placeholder={t("min")}
                  />
                  <Select
                    choices={COMPANY_SIZES_MAX}
                    handleChangeFilters={handleChangeFilters}
                    item='company_size_max'
                    newValue={newFilters.company_size_max}
                    placeholder={t("max")}
                  />
                </div>
              </div>
            }
            {['searchCompanies', 'people', 'searchPeople', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_industry')}</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='company_industry'
                  newValue={newFilters.company_industry}
                  placeholder={t('ex_retail')}
                />
              </div>
            }
            {['searchCompanies', 'people', 'searchPeople', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('company_type')}</span>
                <MultiSelect
                  choices={COMPANY_TYPES}
                  handleChangeFilters={handleChangeFilters}
                  item='company_type'
                  newValue={newFilters.company_type}
                  placeholder={t('ex_privately_held')}
                />
              </div>
            }
            {['searchCompanies', 'people', 'searchPeople', 'companies'].includes(type) &&
              <div className='filters__list__item'>
                <span>{['searchPeople', 'searchCompanies'].includes(type) ? t('company_location') : t('headquarter') }</span>
                <MultiSelect
                  handleChangeFilters={handleChangeFilters}
                  item='company_location'
                  newValue={newFilters.company_location}
                  placeholder={t('ex_north_america')}
                />
              </div>
            }
          </div>
        </div>
      }
      {['people', 'searchPeople', 'companies', 'searchCompanies'].includes(type) &&
        <div className='filters__block'>
          <div className='filters__panel' onClick={() => handleChangeVisibleFilters(3)}>
            <div>
              <FontAwesomeIcon icon={faTowerBroadcast} />
              <span>{t('signals_and_intents')}</span>
            </div>
            <div
              className={visibleFilters.includes(3) ? 'filters__icon' : 'filters__icon closed'}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          <div className={visibleFilters.includes(3) ? 'filters__list' : 'filters__list closed'}>
            {['people', 'searchPeople'].includes(type) &&
              <div className='filters__list__item'>
                <span>{t('has_recently_changed_job')}</span>
                <Toggle handleChangeFilters={handleChangeFilters} item='has_recently_changed_job' newValue={newFilters.has_recently_changed_job} />
              </div>
            }
            {['companies', 'searchCompanies'].includes(type) &&
              <div className= 'filters__list__item'>
                <span>{t('company_is_recruiting')}</span>
                <Toggle handleChangeFilters={handleChangeFilters} item='companyIsRecruiting' newValue={newFilters.companyIsRecruiting} />
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Filters
