import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPaperPlane, faCity, faGear, faCircleNodes, faComments, faListCheck, faArrowRightFromBracket, faUserGroup, faBuilding, faUsersViewfinder, faRectangleList } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from 'contexts/AuthProvider'

import GlobalSearch from 'components/GlobalSearch'
import Logo from 'assets/images/theprocess_text_logo.webp'

const Sidebar = () => {
  const { t } = useTranslation('global')
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const { user } = useAuth()

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <div>
        <div className='sidebar__top'>
          {isSidebarOpen && <img className='sidebar__logo' src={Logo} alt="The Process Logo" /> }
          <FontAwesomeIcon className='sidebar__resize-button' icon={faArrowRightFromBracket} onClick={ ()=>toggleSidebar() } />
        </div>
        <GlobalSearch isSidebarOpen={isSidebarOpen} />
        <nav className='sidebar__menu'>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/'
          >
            <FontAwesomeIcon icon={faHome} />
            {isSidebarOpen && <span>{t('home')}</span>}
          </NavLink>

          {isSidebarOpen ?
            <p className='sidebar__menu__title'>My prospects</p>
            :
            <span className='sidebar__divider'></span>
          }
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/people'
          >
            <FontAwesomeIcon icon={faUserGroup} />
            {isSidebarOpen && <span>{t('people')}</span>}
          </NavLink>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/companies'
          >
            <FontAwesomeIcon icon={faBuilding} />
            {isSidebarOpen && <span>{t('companies')}</span>}
          </NavLink>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/lists'
          >
            <FontAwesomeIcon icon={faRectangleList} />
            {isSidebarOpen && <span>{t('lists')}</span>}
          </NavLink>

          {isSidebarOpen ?
            <p className='sidebar__menu__title'>Find prospects</p>
            :
            <span className='sidebar__divider'></span>
          }
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/search/people'
          >
            <FontAwesomeIcon icon={faUsersViewfinder} />
            {isSidebarOpen && <span>{t('search_people')}</span>}
          </NavLink>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/search/companies'
          >
            <FontAwesomeIcon icon={faCity} />
            {isSidebarOpen && <span>{t('search_companies')}</span>}
          </NavLink>

          {isSidebarOpen ?
            <p className='sidebar__menu__title'>{t('engage')}</p>
            :
            <span className='sidebar__divider'></span>
          }
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/sequences'
          >
            <FontAwesomeIcon icon={faPaperPlane} />
            {isSidebarOpen && <span>{t('sequences')}</span>}
          </NavLink>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/conversations'
          >
            <FontAwesomeIcon icon={faComments} />
            {isSidebarOpen && <span>{t('conversations')}</span>}
          </NavLink>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/tasks'
          >
            <FontAwesomeIcon icon={faListCheck} />
            {isSidebarOpen && <span>{t('tasks')}</span>}
          </NavLink>
        </nav>
      </div>
      <div className='sidebar__last-block'>
        <nav className='sidebar__menu'>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/settings'
          >
            <FontAwesomeIcon icon={faGear} />
            {isSidebarOpen && <span>{t('settings')}</span>}
          </NavLink>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item active' : 'sidebar__menu__item'}
            to='/integrations'
          >
            <FontAwesomeIcon icon={faCircleNodes} />
            {isSidebarOpen && <span>{t('integrations')}</span>}
          </NavLink>
          <NavLink
            className={({ isActive }) => isActive ? 'sidebar__menu__item sidebar__menu__profile active' : 'sidebar__menu__item sidebar__menu__profile'}
            to='/profile'
          >
            <img className='sidebar__menu__profile-picture' src={user.picture_url} alt={user.first_name + ' ' + user.last_name} />
            {isSidebarOpen && <span>{user.first_name + ' ' + user.last_name}</span>}
          </NavLink>
        </nav>
      </div>
    </div> 
  )
}

export default Sidebar
