import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import dateFnsLocales from 'utils/dateFnsLocales'

const useFormattedDate = () => {
  const { i18n } = useTranslation()
  const { t } = useTranslation('global')

  return (isoDate) => {
    if (!isoDate) return t('invalid_date')

    const date = new Date(isoDate)
    if (isNaN(date.getTime())) return t('invalid_date')

    const locale = dateFnsLocales[i18n.language] || dateFnsLocales.en
    const formatPattern = t('date_format')

    return format(date, formatPattern, { locale })
  }
}

export default useFormattedDate
