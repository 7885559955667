import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGroup, faBuilding, faXmark } from '@fortawesome/free-solid-svg-icons'

import { ICONS } from 'constants/filters'

import Text from './Text'
import TextArea from './TextArea'
import FormSelect from './FormSelect'

const ListCreator = ({ handleClose, handleCreateList }) => {
  const { t } = useTranslation('global')
  const [listParams, setListParams] = useState({
    name: '',
    model: '',
    description: '',
    icon: '🚀',
    list_type: 'static'
  })

  const handleChangeListParams = (key, value) => {
    setListParams((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const formIsValid = listParams.name && listParams.model && listParams.icon && listParams.list_type === 'static'

  return (
    <div className='list_creator'>
      <div className='list_creator__close_btn' onClick={handleClose}><FontAwesomeIcon icon={faXmark} /></div>
      <div className='list_creator__item'>
        <div className='list_creator__label'>{t('choose_title')}</div>
        <div className='list_creator__description'>{t('choose_title_description')}</div>
        <div className='list_creator__item__title'>
          <FormSelect
            choices={ICONS}
            handleChangeValue={(value) => handleChangeListParams('icon', value)}
            isEmoji={true}
            value={listParams.icon}
          />
          <Text
            handleChangeValue={(value) => handleChangeListParams('name', value)}
            placeholder={t('title_placeholder')}
            value={listParams.name}
          />
        </div>
      </div>
      <div className='list_creator__item'>
        <div className='list_creator__label'>{t('choose_model')}</div>
        <div className='list_creator__description'>{t('choose_model_description')}</div>
        <div className='list_creator__item__choices'>
          <div
            className={`list_creator__item__choice ${listParams.model === 'contact' ? 'active' : ''}`}
            onClick={() => handleChangeListParams('model', 'contact')}
          >
            <FontAwesomeIcon className='list_creator__item__choice__icon' icon={faUserGroup} />
            <span className='list_creator__item__choice__text'>{t('contacts')}</span>
          </div>
          <div
            className={`list_creator__item__choice ${listParams.model === 'company' ? 'active' : ''}`}
            onClick={() => handleChangeListParams('model', 'company')}
          >
            <FontAwesomeIcon className='list_creator__item__choice__icon' icon={faBuilding} />
            <span className='list_creator__item__choice__text'>{t('companies')}</span>
          </div>
        </div>
      </div>
      <div className='list_creator__item'>
        <div className='list_creator__label'>{t('add_description')}</div>
        <div className='list_creator__description'>{t('add_description_explanation')}</div>
        <TextArea
          handleChangeValue={(value) => handleChangeListParams('description', value)}
          placeholder={t('description_placeholder')}
          value={listParams.description}
        />
      </div>
      <div className={`main-btn ${formIsValid ? '' : 'disabled'}`} onClick={() => handleCreateList(listParams)}>{t('create')}</div>
    </div>
  )
}

export default ListCreator
