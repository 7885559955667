import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleExclamation, faEnvelope, faPhone, faEye, faCheck, faTrash, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { useNotification } from 'contexts/NotificationContext'

import Loader from './Loader'
import Select from './Select'

const ActionsCreator = ({
  activeContact,
  handleChangeActiveContact,
  handleChangeSequence,
  handleSaveSequence,
  sequence
}) => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const [lists, setLists] = useState([])
  const [selectedListId, setSelectedListId] = useState(null)
  const [contacts, setContacts] = useState([])

  const handleChangeSelectedListId = (key, value) => {
    setSelectedListId(value)
  }

  const fetchLists = () => {
    apiClient.get(buildApiUrl('/lists', {model: 'contact'}))
      .then((response) => {
        response.data && setLists(response.data)
      })
  }

  const fetchContactsLists = (listsIds) => {
      setIsLoading(true)
      apiClient
        .get(buildApiUrl(`/contacts`, { lists_ids: (listsIds && listsIds.length > 0 ? listsIds : 'none'), sequence_id: sequence.id}))
        .then((response) => {
          setContacts(response.data)
        })
        .finally(() => {
          setIsLoading(false)
        })
  }

  const handleSelectList = (listIdToAdd) => {
    const updatedListIds = [...sequence.list_ids, listIdToAdd]
  
    handleChangeSequence('list_ids', updatedListIds)
      .then((updatedSequence) => {
        return handleSaveSequence(updatedSequence)
      })
      .then(() => {
        return fetchContactsLists(updatedListIds)
      })
  }
  
  const handleRemoveList = (listIdToRemove) => {
    const updatedListIds = sequence.list_ids.filter((id) => id != listIdToRemove)
  
    handleChangeSequence('list_ids', updatedListIds)
      .then((updatedSequence) => {
        return handleSaveSequence(updatedSequence)
      })
      .then(() => {
        return fetchContactsLists(updatedListIds)
      })
  }
  
  
  const handleValidateContact = (contactToValidateId) => {
    if(sequence) {
      apiClient.post(buildApiUrl(`/sequences/${sequence.id}/validate_contact`), {contact_id: contactToValidateId})
      .then(() => {
        showNotification(t('contact_validated') , 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
    }
  }

  useEffect(() => {
    fetchLists()
    fetchContactsLists(sequence.list_ids)
  }, [])

  useEffect(() => {
    setSelectedListId(null)
  }, [sequence])

  const filteredList = lists && lists.length > 0 ? lists.filter(l => !sequence.list_ids.includes(l.id.toString())).map(l => ({ value: l.id, id: l.name })) : []
  const formattedTotalList = lists && lists.length > 0 ? lists.map(l => ({ value: l.id, id: l.name })) : []

  return (
    <div className='actions_creator'>
      {sequence.list_ids && sequence.list_ids.length > 0 && sequence.list_ids.map(listId => {
        return (
          <div key={listId} className='actions_creator__list already-added'>
            <Select
              choices={formattedTotalList}
              isDisabled={true}
              item={`list${listId}`}
              newValue={listId}
            />
            <div className='actions_creator__list__remove' onClick={() => handleRemoveList(listId)}><FontAwesomeIcon icon={faTrash} /></div>
          </div>
        )
      })}
      {filteredList && filteredList.length > 0 &&
        <div className='actions_creator__list'>
          <Select
            choices={filteredList}
            handleChangeFilters={handleChangeSelectedListId}
            item='newList'
            newValue={selectedListId}
          />
          <div className='actions_creator__list__select' onClick={() => handleSelectList(selectedListId)}><FontAwesomeIcon icon={faPlusCircle} /></div>
        </div>
      }
      <div className='actions_creator__contacts'>
      {contacts && contacts.length > 0 && isLoading && <Loader />}
      {contacts && contacts.length > 0 && !isLoading &&
        <div className='actions_creator__contacts__header'>
          <div>{t('name')}</div>
          <div></div>
          <div>{t('company')}</div>
          <div><FontAwesomeIcon icon={faLinkedin} /></div>
          <div><FontAwesomeIcon icon={faEnvelope} /></div>
          <div><FontAwesomeIcon icon={faPhone} /></div>
          <div>{t('validation')}</div>
        </div>
      }
        {contacts && contacts.length > 0 && contacts.map(contact => {
          return (
            <div key={contact.id} className={`actions_creator__contact ${activeContact?.id == contact.id ? 'active' : ''}`}>
              <img className='actions_creator__contact__img' src={contact.picture_url} alt={contact.first_name + ' ' + contact.last_name} />
              <div>{contact.first_name} {contact.last_name}</div>
              <div>{contact.company_name}</div>
              {contact.linkedin_id ?
                <FontAwesomeIcon className='actions_creator__contact__icon__green' icon={faCheckCircle} />
                :
                <FontAwesomeIcon icon={faCircleExclamation} />
              }
              {contact.is_validated_email ?
                <FontAwesomeIcon className='actions_creator__contact__icon__green' icon={faCheckCircle} />
                :
                <FontAwesomeIcon icon={faCircleExclamation} />
              }
              {contact.is_validated_phone ?
                <FontAwesomeIcon className='actions_creator__contact__icon__green' icon={faCheckCircle} />
                :
                <FontAwesomeIcon icon={faCircleExclamation} />
              }
              {contact.sequence_status === 'in_progress' ?
                <div className='actions_creator__contact__ctas'>
                  <div className='alt-btn small' onClick={() => handleChangeActiveContact(contact)}><FontAwesomeIcon icon={faEye} /></div>
                  <div className='main-btn small disabled'><FontAwesomeIcon icon={faSpinner} /></div>
                </div>
                :
                <div className='actions_creator__contact__ctas'>
                  <div className='alt-btn small' onClick={() => handleChangeActiveContact(contact)}><FontAwesomeIcon icon={faEye} /></div>
                  <div className='main-btn small' onClick={() => handleValidateContact(contact.id)}><FontAwesomeIcon icon={faCheck} /></div>
                </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ActionsCreator
