import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faPlusCircle, faCircleCheck, faBuilding, faEye, faPlus } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import Loader from 'components/Loader'
import Pagination from 'components/Pagination'

const SearchCompaniesResults = ({
  companies,
  handleCancelFilters,
  handleImportCompanies,
  handleSubmitFilters,
  hasNewFilters,
  isLoading,
  searchContext
}) => {
  const { t } = useTranslation('global')
  const [selectedCompanies, setSelectedCompanies] = useState([])

  const handleToggleCompany = (companyId) => {
    setSelectedCompanies((prevSelectedCompanies) => {
      if (prevSelectedCompanies.includes(companyId)) {
        return prevSelectedCompanies.filter((id) => id !== companyId)
      } else {
        return [...prevSelectedCompanies, companyId]
      }
    })
  }

  const handleToggleAllCompanies = () => {
    setSelectedCompanies(() => {
      if (allSelected) {
        return []
      } else {
        return companies.map((company) => company.linkedin_id)
      }
    })
  }

  const handleAddToList = () => {
    console.log('Add Modal')
  }

  useEffect(() => {
    setSelectedCompanies([])
  }, [companies])

  const allSelected = companies && companies.length > 0 ? companies.every((company) => selectedCompanies.includes(company.linkedin_id)) : []

  if (isLoading) { return <div className='results'><Loader /></div> }

  return (
    <div className={hasNewFilters ? 'results blurred' : 'results'}>
      {selectedCompanies && selectedCompanies.length > 0 && !hasNewFilters &&
        <>
          <div className='results__multiple_actions'>
            <p className='results__multiple_actions_text'>{t('contacts_selected', {count: selectedCompanies.length})}</p>
            <div className='results__multiple_actions_item'>
              <div className={`alt-btn`} onClick={() => handleImportCompanies(selectedCompanies)}>
                <FontAwesomeIcon icon={faPlus} />
                <span className='results__multiple_actions__cta_text'>{t('import')}</span>
              </div>
            </div>
            <div className='results__multiple_actions_item'>
              <div className={`alt-btn`} onClick={handleAddToList}>
                <FontAwesomeIcon icon={faPlus} />
                <span className='results__multiple_actions__cta_text'>{t('add_to_list')}</span>
              </div>
            </div>
          </div>
        </>
      }
      {hasNewFilters && (
        <div className='results__filters-layer'>
          <p className='results__filters-layer__text'>{t('apply_filters_message')}</p>
          <div className='results__filters-layer__ctas'>
            <div className='results__filters-layer__ctas__cancel' onClick={handleCancelFilters}>{t('cancel')}</div>
            <div className='main-btn' onClick={() => handleSubmitFilters(null)}>{t('apply_filters')}</div>
          </div>
        </div>
      )}
      {!hasNewFilters && companies && companies.length > 0 &&
        <>
          <table className='results__table results__table__search__companies'>
            <thead>
              <tr>
                <th><input type='checkbox' checked={allSelected} onChange={handleToggleAllCompanies} /></th>
                <th>{t('name')}</th>
                <th>{t('linkedin')}</th>
                <th>{t('industry')}</th>
                <th>{t('company_size')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => {
                return (
                  <tr key={company.linkedin_id}>
                    
                    <td>
                      <input
                        checked={selectedCompanies && selectedCompanies.includes(company.linkedin_id)}
                        onChange={() => handleToggleCompany(company.linkedin_id)}
                        type='checkbox'
                      />
                    </td>
                    <td className='results__table__with-picture'>
                      {company.picture_url ?
                        <div className='results__table__with-picture__checked'>
                          <img src={company.picture_url} alt={company.name} className='results__table__picture' />
                          {company.already_imported && <FontAwesomeIcon className='results__table__with-picture__checked_icon' icon={faCircleCheck} />}
                        </div>
                        :
                        <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faBuilding} />
                      }
                      <a className='results__table__link' href={'https://www.linkedin.com/company/' + company.linkedin_id} target='_blank' rel="noreferrer">
                        {company.name}
                        <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                      </a>
                    </td>
                    <td>
                      {company.linkedin_id &&
                        <a className='results__table__link' href={'https://www.linkedin.com/company/' + company.linkedin_id} target='_blank' rel="noreferrer">
                          <FontAwesomeIcon className='results__table__linkedin__icon' icon={faLinkedin} />
                        </a>
                      }
                    </td>
                    <td>{t(company.industry)}</td>
                    <td>{company.company_size}</td>
                    <td className='results__actions'>
                      {!company.already_imported ?
                        <div className='results__actions__item' onClick={() => handleImportCompanies([company.linkedin_id])} >
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </div>
                        :
                        <div className='results__actions__item'>
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Pagination paginationContext={searchContext} handleSubmitFilters={handleSubmitFilters} />
        </>
      }
    </div>
  )
}

export default SearchCompaniesResults
