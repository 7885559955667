import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from 'contexts/AuthProvider'

const Topbar = ({ cta, ctaAction, ctaDisabled, icon, number, title, description }) => {
  const { t } = useTranslation('global')
  const { user } = useAuth()

  return (
    <div className='topbar'>
      <div className='topbar__common'>
        <div className='topbar__common__ia'>
          <FontAwesomeIcon className='topbar__common__ia__icon' icon={faWandMagicSparkles} />
          <span>{t('ai_placeholder')}</span>
        </div>
        <div className='topbar__common__right'>
          <div className='topbar__common__notifications'>
            <FontAwesomeIcon className='topbar__common__notifications__icon' icon={faBell} />
            <div className='topbar__common__notifications__round'></div>
          </div>
          {user.account &&
            <div className='topbar__common__company'>
              <img className='topbar__common__company__logo' src={user.account.logo_url} alt='The Process Logo' />
              <div className='topbar__common__company__infos'>
                <p className='topbar__common__company__name'>{user.account.name}</p>
                <p className='topbar__common__company__plan'>Pro plan</p>
              </div>
            </div>
          }
        </div>
      </div>
      <div className='topbar__title'>
        <div className='topbar__header__left'>
          <div className='topbar__title__left'>
            {icon}
            <h1>{title}</h1>
            {number ? <p>({number})</p> : ''}
            {number === 0 && <p>(0)</p>}
          </div>
          <p className='topbar__description__left'>
            {description}
          </p>
        </div>
        {cta && <div className={`main-btn ${ctaDisabled ? 'disabled' : ''}`} onClick={ctaAction}>{cta}</div>}
     </div>
    </div> 
  )
}

export default Topbar
