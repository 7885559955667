import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faUserPlus, faCircleCheck, faBuilding, faPlus, faEye } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import Loader from 'components/Loader'
import Pagination from 'components/Pagination'

const SearchPeopleResults = ({
  contacts,
  handleImportContacts,
  handleCancelFilters,
  handleSubmitFilters,
  hasNewFilters,
  isLoading,
  searchContext
}) => {
  const { t } = useTranslation('global')
  const [selectedContacts, setSelectedContacts] = useState([])

  const handleToggleContact = (contactId) => {
    setSelectedContacts((prevSelectedContacts) => {
      if (prevSelectedContacts.includes(contactId)) {
        return prevSelectedContacts.filter((id) => id !== contactId)
      } else {
        return [...prevSelectedContacts, contactId]
      }
    })
  }

  const handleToggleAllContacts = () => {
    setSelectedContacts(() => {
      if (allSelected) {
        return []
      } else {
        return contacts.map((contact) => contact.linkedin_id)
      }
    })
  }

  const handleAddToList = () => {
    console.log('Add Modal')
  }

  useEffect(() => {
    setSelectedContacts([])
  }, [contacts])

  const allSelected = contacts && contacts.length > 0 ? contacts.every((contact) => selectedContacts.includes(contact.linkedin_id)) : []

  if (isLoading) { return <div className='results'><Loader /></div> }

  return (
    <div className={hasNewFilters ? 'results blurred' : 'results'}>
      {selectedContacts && selectedContacts.length > 0 && !hasNewFilters &&
        <>
          <div className='results__multiple_actions'>
            <p className='results__multiple_actions_text'>{t('contacts_selected', {count: selectedContacts.length})}</p>
            <div className='results__multiple_actions_item'>
              <div className={`alt-btn`} onClick={() => handleImportContacts(selectedContacts)}>
                <FontAwesomeIcon icon={faUserPlus} />
                <span className='results__multiple_actions__cta_text'>{t('import')}</span>
              </div>
            </div>
            <div className='results__multiple_actions_item'>
              <div className={`alt-btn`} onClick={handleAddToList}>
                <FontAwesomeIcon icon={faPlus} />
                <span className='results__multiple_actions__cta_text'>{t('add_to_list')}</span>
              </div>
            </div>
          </div>
        </>
      }
      {hasNewFilters && (
        <div className='results__filters-layer'>
          <p className='results__filters-layer__text'>{t('apply_filters_message')}</p>
          <div className='results__filters-layer__ctas'>
            <div className='results__filters-layer__ctas__cancel' onClick={handleCancelFilters}>{t('cancel')}</div>
            <div className='main-btn' onClick={() => handleSubmitFilters(null)}>{t('apply_filters')}</div>
          </div>
        </div>
      )}
      {!hasNewFilters && contacts && contacts.length > 0 &&
        <>
          <table className='results__table'>
            <thead>
              <tr>
                <th><input type='checkbox' checked={allSelected} onChange={handleToggleAllContacts}/></th>
                <th>{t('full_name')}</th>
                <th>{t('linkedin')}</th>
                <th>{t('job_title')}</th>
                <th>{t('company')}</th>
                <th>{t('time_in_company')}</th>
                <th>{t('location')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => {
                return (
                  <tr key={contact.linkedin_id}>
                    <td>
                      <input
                        type='checkbox'
                        id={contact.linkedin_id}
                        checked={selectedContacts && selectedContacts.includes(contact.linkedin_id)}
                        onChange={() => handleToggleContact(contact.linkedin_id)}
                      />
                    </td>
                    <td className='results__table__with-picture'>
                      {contact.picture_url ?
                        <div className='results__table__with-picture__checked'>
                          <img src={contact.picture_url} alt={contact.name} className='results__table__picture' />
                          {contact.already_imported && <FontAwesomeIcon className='results__table__with-picture__checked_icon' icon={faCircleCheck} />}
                        </div>
                        :
                        <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faBuilding} />
                      }
                      <a className='results__table__link' href={'https://www.linkedin.com/in/' + contact.linkedin_id} target='_blank' rel="noreferrer">
                        {contact.full_name}
                        <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                      </a>
                    </td>
                    <td>
                      {contact.linkedin_id &&
                        <a className='results__table__link' href={'https://www.linkedin.com/in/' + contact.linkedin_id} target='_blank' rel="noreferrer">
                          <FontAwesomeIcon className='results__table__linkedin__icon' icon={faLinkedin} />
                        </a>
                      }
                    </td>
                    <td>{contact.job_title}</td>
                    <td>{contact.company_name}</td>
                    <td>
                      {contact.tenure_at_company && (
                        <>
                          {contact.tenure_at_company.years && `${contact.tenure_at_company.years} ${t('years')} `}
                          {contact.tenure_at_company.years && contact.tenure_at_company.months && ` ${t('and')} `}
                          {contact.tenure_at_company.months && `${contact.tenure_at_company.months} ${t('months')}`}
                        </>
                      )}
                    </td>
                    <td>{contact.location}</td>
                    <td className='results__actions'>
                      {!contact.already_imported ?
                        <div className='results__actions__item' onClick={() => handleImportContacts([contact.linkedin_id])} >
                          <FontAwesomeIcon icon={faUserPlus} />
                        </div>
                        :
                        <div className='results__actions__item'>
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {searchContext && searchContext.total_page_count > 1 &&
            <Pagination paginationContext={searchContext} handleSubmitFilters={handleSubmitFilters} />
          }
        </>
      }
    </div>
  )
}

export default SearchPeopleResults
