import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faList, faBuilding, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { useNotification } from 'contexts/NotificationContext'

import Loader from 'components/Loader'
import Select from 'components/Select'

const CompaniesResults = ({
  companies,
  companyLists,
  handleDestroyCompany,
  handleRemoveFromList,
  handleCancelFilters,
  handleSubmitFilters,
  hasNewFilters,
  isLoading,
  type
}) => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState([])
  const [selectedListId, setSelectedListId] = useState(null)

  const handleToggleCompany = (companyId) => {
    setSelectedCompaniesIds((prevSelectedCompaniesIds) => {
      if (prevSelectedCompaniesIds.includes(companyId)) {
        return prevSelectedCompaniesIds.filter((id) => id !== companyId)
      } else {
        return [...prevSelectedCompaniesIds, companyId]
      }
    })
  }

  const handleToggleAllCompanies = () => {
    setSelectedCompaniesIds(() => {
      if (allSelected) {
        return []
      } else {
        return companies.map((company) => company.id);
      }
    })
  }

  const handleChangeSelectedCompanyList = (key, value) => {
    setSelectedListId(value)
  }

  const handleAddToList = () => {
    apiClient.post(buildApiUrl(`/lists/${selectedListId}/add_contacts`), {ids: selectedCompaniesIds})
    .then(() => {
      showNotification(t('company_added_to_list') , 'success')
    })
    .catch(() => {
      showNotification(t('error_occured') , 'error')
    })
  }

  useEffect(() => {
    setSelectedListId(null)
    setSelectedCompaniesIds([])
  }, [companies])

  const formattedCompanyLists = companyLists && companyLists.length > 0 ? companyLists.map(l => ({ value: l.id, id: l.name })) : []

  const allSelected = companies.every((contact) => selectedCompaniesIds.includes(contact.id))

  if (isLoading) { return <div className='results'><Loader /></div> }

  return (
    <div className={hasNewFilters ? 'results blurred' : 'results'}>
      {hasNewFilters && (
        <div className='results__filters-layer'>
          <p className='results__filters-layer__text'>{t('apply_filters_message')}</p>
          <div className='results__filters-layer__ctas'>
            <div className='results__filters-layer__ctas__cancel' onClick={handleCancelFilters}>{t('cancel')}</div>
            <div className='main-btn' onClick={handleSubmitFilters}>{t('apply_filters')}</div>
          </div>
        </div>
      )}
      {type === 'companies' && selectedCompaniesIds && selectedCompaniesIds.length > 0 && !hasNewFilters &&
        <div className='results__multiple_actions'>
          <Select
            choices={formattedCompanyLists.filter(list => list.value !== 0)}
            handleChangeFilters={handleChangeSelectedCompanyList}
            item='companyList'
            newValue={selectedListId}
          />
          <div className='results__multiple_actions__cta_container'>
            <div className={`main-btn ${selectedListId ? '' : 'disabled'}`} onClick={handleAddToList}>
              <FontAwesomeIcon icon={faPlus} />
              <span className='results__multiple_actions__cta_text'>{t('add_to_list')}</span>
            </div>
          </div>
        </div>
      }
       {type === 'list' && selectedCompaniesIds && selectedCompaniesIds.length > 0 &&
        <div className='results__multiple_actions'>
          <div className='results__multiple_actions__cta_container'>
            <div className='remove-btn' onClick={() => handleRemoveFromList(selectedCompaniesIds)}>
              <FontAwesomeIcon icon={faTrash} />
              <span className='results__multiple_actions__cta_text'>{t('remove_from_list')}</span>
            </div>
          </div>
        </div>
      }
      {!hasNewFilters && companies.length > 0 &&
        <table className='results__table'>
          <thead>
            <tr>
              <th><input type='checkbox' checked={allSelected} onChange={handleToggleAllCompanies} /></th>
              <th>{t('name')}</th>
              <th>{t('industry')}</th>
              <th>{t('number_of_employees')}</th>
              <th>{t('headquarter')}</th>
              <th>{t('website_url')}</th>
              <th>{t('number_of_contacts')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => {
              return (
                <tr key={company.id}>
                  <td>
                    <input
                      type='checkbox'
                      id={company.id}
                      checked={selectedCompaniesIds && selectedCompaniesIds.includes(company.id)}
                      onChange={() => handleToggleCompany(company.id)}
                    />
                  </td>
                  <td className='results__table__with-picture'>
                    {company.picture_url ?
                      <img src={company.picture_url} alt={company.name} className='results__table__picture' />
                      :
                      <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faBuilding} />
                    }
                    {company.linkedin_id ?
                      <a className='results__table__link' href={'https://www.linkedin.com/company/' + company.linkedin_id} target='_blank' rel="noreferrer">
                        {company.name}
                        <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                      </a>
                      :
                      <div className='results__table__link'>
                        {company.name}
                      </div>
                    }
                  </td>
                  <td>{t(company.industry)}</td>
                  <td>{company.size}</td>
                  <td>{t(company.country)}</td>
                  <td>{company.website_url}</td>
                  <td>{company.number_of_contacts}</td>
                  <td className='results__actions'>
                    {type === 'companies' && <FontAwesomeIcon className='results__actions__item' icon={faList} />}
                    <FontAwesomeIcon className='results__actions__item delete' icon={faTrash} onClick={() => handleDestroyCompany(company)} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      }
    </div>
  )
}

export default CompaniesResults
