import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faWarning } from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from 'react-i18next'

const Notification = ({ message, status, onClose }) => {
  const { t } = useTranslation('global')

  if (!message || !status) {
    return null
  }

  return (
    <div className={`notification ${status}`} onClick={onClose}>
      {status === 'success' && <FontAwesomeIcon icon={faCheck} />}
      {status === 'error' && <FontAwesomeIcon icon={faWarning} />}
      <div className='notification__message'>{t(message)}</div>
    </div>
  )
}

export default Notification
