import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICONS } from 'constants/filters'

import ActionsCreator from 'components/ActionsCreator'
import FormSelect from 'components/FormSelect'
import Loader from 'components/Loader'
import SequenceStepCreator from '../components/SequenceStepCreator'
import Text from 'components/Text'
import TextArea from 'components/TextArea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGears, faList, faUsers } from '@fortawesome/free-solid-svg-icons'

const SequenceBuilder = ({
  activeContact,
  currentStep,
  handleChangeActiveContact,
  handleChangeCurrentStep,
  handleChangeSequence,
  handleChangeStage,
  handleSaveSequence,
  isLoading,
  sequence,
  stage
}) => {
  const { t } = useTranslation('global')
  const [previousSequenceId, setPreviousSequenceId] = useState(sequence.id)

  const handleChangeFromFirstStep = () => {
    handleSaveSequence(sequence).then(() => {
      handleChangeStage(2)
    })
  }

  const getStepStatus = (testedStage) => {
    if (testedStage === 1) {
      if (!!sequence.name && stage === testedStage) return 'complete active'
      else if (!!sequence.name) return 'complete'
      else if(stage === testedStage) return 'active'
    } else if (testedStage === 2) {
      if (sequence.steps && sequence.steps.length > 0 && stage === testedStage) return 'complete active'
      else if (sequence.steps && sequence.steps.length > 0) return 'complete'
      else if(stage === testedStage) return 'active'
    } else if (testedStage === 3) {
      if (sequence.actions && sequence.actions.length > 0 && stage === testedStage) return 'complete active'
      else if (sequence.actions && sequence.actions.length > 0) return 'complete'
      else if(stage === testedStage) return 'active'
    } else {
      return 'incomplete'
    }
  }

  useEffect(() => {
    if (sequence && (sequence.id !== previousSequenceId)) {
      if(!!sequence.name && sequence.steps && sequence.steps.length > 0) {
        handleChangeStage(3)
      } else if(!!sequence.name && sequence.steps && sequence.steps.length === 0) {
        handleChangeStage(2)
      } else {
        handleChangeStage(1)
      }
      setPreviousSequenceId(sequence.id)
    }
  }, [sequence.id, previousSequenceId])

  const formIsValid = !!sequence.name

  return (
    <div className='sequence-builder'>
      {isLoading ?
        <Loader />
        :
        <>
          <div className='sequence-builder__header'>
            <div className={`sequence-builder__header__item ${getStepStatus(1)}`} onClick={() => handleChangeStage(1)}><FontAwesomeIcon icon={faGears} /></div>
            <div className='sequence-builder__header__separator'></div>
            <div className={`sequence-builder__header__item ${getStepStatus(2)}`} onClick={() => handleChangeStage(2)}><FontAwesomeIcon icon={faList} /></div>
            <div className='sequence-builder__header__separator'></div>
            <div className={`sequence-builder__header__item ${getStepStatus(3)}`} onClick={() => handleChangeStage(3)}><FontAwesomeIcon icon={faUsers} /></div>
          </div>
          {stage === 1 &&
            <div className='sequence-builder__step'>
              <p className='sequence-builder__step__intro'>{t('sequence_step_1_intro')}</p>
              <div className='sequence-builder__step__form-item'>
                <p className='sequence-builder__step__label'>{t('title_label')}</p>
                <p className='sequence-builder__step__description'>{t('title_description')}</p>
                <div className='sequence-builder__step__title'>
                  <FormSelect
                    choices={ICONS}
                    handleChangeValue={(value) => handleChangeSequence('icon', value)}
                    isEmoji={true}
                    value={sequence.icon}
                  />
                  <Text
                    handleChangeValue={(value) => handleChangeSequence('name', value)}
                    item='name'
                    placeholder={t('sequence_title_placeholder')}
                    value={sequence.name}
                  />
                </div>
              </div>
              <div className='sequence-builder__step__form-item'>
                <p className='sequence-builder__step__label'>{t('description_label')}</p>
                <p className='sequence-builder__step__description'>{t('description_description')}</p>
                <TextArea
                  handleChangeValue={(value) => handleChangeSequence('description', value)}
                  item='description'
                  placeholder={t('sequence_description_placeholder')}
                  value={sequence.description}
                />
              </div>
              <div className={`main-btn ${formIsValid ? '' : 'disabled'}`} onClick={() => handleChangeFromFirstStep()}>{t('save')}</div>
            </div>
          }
          {
            stage === 2 &&
            <div className='sequence-builder__step'>
              <p className='sequence-builder__step__intro'>{t('sequence_step_2_intro')}</p>
              <SequenceStepCreator
                currentStep={currentStep}
                handleChangeCurrentStep={handleChangeCurrentStep}
                handleChangeStage={handleChangeStage}
                stage={stage}
                steps={sequence.steps}
              />
            </div>
          }
          { stage === 3 &&
            <div className='sequence-builder__step'>
              <p className='sequence-builder__step__intro'>{t('sequence_step_3_intro')}</p>
              <ActionsCreator
                activeContact={activeContact}
                handleChangeActiveContact={handleChangeActiveContact}
                handleChangeSequence={handleChangeSequence}
                handleSaveSequence={handleSaveSequence}
                sequence={sequence}
              />
            </div>
          }
        </>
      }
    </div>
  )
}

export default SequenceBuilder
