import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { useNotification } from 'contexts/NotificationContext'

import CompaniesResults from 'features/CompaniesResults'
import Filters from 'features/Filters'
import Layout from 'layouts/Layout'
import Tabs from 'components/Tabs'
import Topbar from 'layouts/Topbar'

const Companies = () => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const [newFilters, setNewFilters] = useState({})
  const [currentFilters, setCurrentFilters] = useState(newFilters)
  const [companies, setCompanies] = useState([])
  const [companyLists, setCompanyLists] = useState([{id: 0, name: t('all_companies')}])
  const [activeList, setActiveList] = useState({id: 0, name: t('all_companies')})

  const handleChangeFilters = (key, value) => {
    setNewFilters((prevNewFilters) => {
      const updatedFilters = { ...prevNewFilters }
  
      if (!value) {
        delete updatedFilters[key]
      } else {
        updatedFilters[key] = value
      }
  
      return updatedFilters
    })
  }
  
  const handleSubmitFilters = () => {
    setCurrentFilters(newFilters)
  }

  const handleCancelFilters = () => {
    setNewFilters(currentFilters)
  }

  const handleChangeActiveList = (list) => {
    setActiveList(list)
  }

  const handleFetchCompanies = () => {
    setIsLoading(true)
    apiClient
    .get(buildApiUrl('/companies', newFilters))
    .then((response) => {
      setCompanies(response.data)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const handleFetchCompanyLists = () => {
    apiClient.get(buildApiUrl('/lists', {model: 'company', list_type: 'static'}))
      .then((response) => {
        setCompanyLists((prevLists) => {
          return [...prevLists, ...response.data]
        })
      })
  }

  const handleDestroyCompany = (company) => {
    apiClient
      .delete(buildApiUrl(`/companies/${company.id}`))
      .then(() => {
        setCompanies((prevCompanies) => 
          prevCompanies.filter((c) => c.id !== company.id)
        )
        showNotification(t('company_deleted') , 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  useEffect(() => {
    handleFetchCompanyLists()
  }, [])

  useEffect(() => {
    if (activeList && activeList.id) { handleChangeFilters('lists_ids', [activeList.id]) }
    if (activeList && activeList.id === 0) {
      setNewFilters({})
    }
  }, [activeList])

  useEffect(() => {
    handleFetchCompanies()
  }, [currentFilters])

  const hasNewFilters =
    Object.keys(newFilters).length !== Object.keys(currentFilters).length || 
    Object.keys(newFilters).some((key) => newFilters[key] !== currentFilters[key])

  return (
    <Layout>
      <Topbar
        icon={<FontAwesomeIcon icon={faBuilding} />}
        title={t('companies')}
        number={companies ? companies.length : 0}
      />
      <Tabs
        activeTab={activeList}
        handleChangeTab={handleChangeActiveList}
        tabs={companyLists}
      />
      <div className='companies'>
        <Filters
          allCompanies={companies}
          allCompanyLists={companyLists}
          handleChangeFilters={handleChangeFilters}
          type='companies'
          newFilters={newFilters}
        />
        <CompaniesResults
          companies={companies}
          companyLists = {companyLists}
          handleCancelFilters={handleCancelFilters}
          handleDestroyCompany={handleDestroyCompany}
          handleSubmitFilters={handleSubmitFilters}
          hasNewFilters={hasNewFilters}
          isLoading={isLoading}
          type='companies'
        />
      </div>
    </Layout>
  )
}

export default Companies
