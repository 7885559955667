import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

import TextArea from './TextArea'

const SequenceStepLinkedinInvite = ({ handleChangeCurrentStep, handleSaveStep, currentStep }) => {
  const { t } = useTranslation('global')
  const [text, setText] = useState('')

  const handleSubmitText = () => {
    const newStep = { ...currentStep, content: text }
    handleChangeCurrentStep(newStep)
    handleSaveStep(newStep)
  }

  const handleChangeText = (newText) => {
    newText.length <= 200 && setText(newText)
  }

  useEffect(() => {
    currentStep && setText(currentStep.content)
  }, [currentStep])

  const formIsValid = !!text

  return (
    <div className='sequence-step-action'>
      <div className='sequence-step-action__title__container'>
        <FontAwesomeIcon icon={faUserPlus} />
        <p className='sequence-step-action__title'>{t('linkedin_invitation_message')}</p>
      </div>
      <p className='sequence-step-action__desc'>{t('linkedin_invitation_message_desc')}</p>
      <div className='sequence-step-action__text__container'>
        <TextArea
          handleChangeValue={handleChangeText}
          value={text}
        />
        <p
          className={`sequence-step-action__text__counter ${text && text.length === 200 ? 'limit' : ''}`}
        >
          {text && text.length === 200 && <FontAwesomeIcon icon={faTriangleExclamation} />}
          <span>{(text && text.length) || 0} / 200</span>
        </p>
      </div>
      <div className={`main-btn ${formIsValid ? '' : 'disabled'}`} onClick={handleSubmitText}>{t('save')}</div>
    </div>
  )
}

export default SequenceStepLinkedinInvite
