import React from 'react'
import { useTranslation } from 'react-i18next'

const TextArea = ({disabled, handleChangeValue, placeholder, value}) => {
  const { t } = useTranslation('global')

  return (
    <div className='text-area'>
      <textarea
        className={`${disabled ? 'disabled' : ''} text-area__input`}
        onChange={(e) => handleChangeValue(e.target.value)}
        placeholder={placeholder || t('type')}
        value={value || ''}
      />
    </div>
  )
}

export default TextArea
