import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { useNotification } from 'contexts/NotificationContext'

import Layout from 'layouts/Layout'
import Filters from 'features/Filters'
import PeopleResults from 'features/PeopleResults'
import Tabs from 'components/Tabs'
import Topbar from 'layouts/Topbar'

const People = () => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const [newFilters, setNewFilters] = useState({})
  const [currentFilters, setCurrentFilters] = useState(newFilters)
  const [contacts, setContacts] = useState([])
  const [lists, setLists] = useState([{id: 0, name: t('all_contacts'), model: 'contact'}])
  const [activeList, setActiveList] = useState({id: 0, name: t('all_contacts'), model: 'contact'})

  const handleChangeFilters = (key, value) => {
    setNewFilters((prevNewFilters) => {
      const updatedFilters = { ...prevNewFilters }
  
      if (!value) {
        delete updatedFilters[key]
      } else {
        updatedFilters[key] = value
      }
  
      return updatedFilters
    })
  }
  
  const handleSubmitFilters = () => {
    setCurrentFilters(newFilters)
  }

  const handleCancelFilters = () => {
    setNewFilters(currentFilters)
  }

  const handleChangeActiveList = (list) => {
    setActiveList(list)
  }

  const handleFetchContacts = () => {
    setIsLoading(true)

    const filtersToFetch = Object.fromEntries(
      Object.entries(newFilters).map(([key, value]) => {
        if (['company_id', 'company_industry', 'company_list_id'].includes(key)) {
          if (Array.isArray(value) && value.every(item => item.value !== undefined)) {
            return [key, value.map(item => item.value)]
          } else if (value && typeof value === 'object' && 'value' in value) {
            return [key, value.value]
          } else {
            return [key, value]
          }
        } else {
          if (Array.isArray(value) && value.every(item => item.value !== undefined)) {
            return [key, value.map(item => item.text)]
          } else if (value && typeof value === 'object' && 'value' in value) {
            return [key, value.text]
          } else {
            return [key, value]
          }
        }
      })
    )

    apiClient
      .post(buildApiUrl('/contacts/filter'), filtersToFetch)
      .then((response) => {
        setContacts(response.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleDestroyContact = (contact) => {
    apiClient
      .delete(buildApiUrl(`/contacts/${contact.id}`))
      .then(() => {
        setContacts((prevContacts) => 
          prevContacts.filter((c) => c.id !== contact.id)
        )
        showNotification(t('contact_deleted') , 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  const handleFetchLists = () => {
    apiClient.get(buildApiUrl('/lists', {list_type: 'static'}))
      .then((response) => {
        setLists((prevLists) => {
          return [...prevLists, ...response.data]
        })
      })
  }

  const handleEnrichPhone = (contact) => {
    apiClient.post(buildApiUrl(`/contacts/${contact.id}/enrich_phone`))
    .then((response) => {
      showNotification(response.data.message, response.data.status)
    })
    .catch(() => {
      showNotification(t('error_occured') , 'error')
    })
  }

  useEffect(() => {
    handleFetchLists()
  }, [])

  useEffect(() => {
    if (activeList && activeList.id) { handleChangeFilters('lists_ids', [activeList.id]) }
    if (activeList && activeList.id === 0) { setNewFilters({}) }
  }, [activeList])

  useEffect(() => {
    handleFetchContacts()
  }, [currentFilters])

  const hasNewFilters = (
    Object.keys(newFilters).length !== Object.keys(currentFilters).length ||
    Object.keys(newFilters).some((key) => newFilters[key] !== currentFilters[key])
  )

  const contactLists = lists.filter(list => list.model === 'contact')
  const companyLists = (lists.filter(list => list.model === 'company') || [])
    .map(c => ({ text: c.name, value: c.id }))

  return (
    <Layout>
      <Topbar
        description={t('people_description')}
        icon={<FontAwesomeIcon icon={faUserGroup} />}
        title={t('people')}
        number={contacts ? contacts.length : 0}
      />
      <Tabs
        activeTab={activeList}
        handleChangeTab={handleChangeActiveList}
        tabs={contactLists}
      />
      <div className='people'>
        <Filters
          companyLists={companyLists}
          handleChangeFilters={handleChangeFilters}
          type='people'
          newFilters={newFilters}
        />
        <PeopleResults
          contactLists = {contactLists}
          contacts={contacts}
          handleCancelFilters={handleCancelFilters}
          handleEnrichPhone={(contact) => handleEnrichPhone(contact)}
          handleDestroyContact={(contact) => handleDestroyContact(contact)}
          handleSubmitFilters={handleSubmitFilters}
          hasNewFilters={hasNewFilters}
          isLoading={isLoading}
          type='people'
        />
      </div>
    </Layout>
  )
}

export default People
