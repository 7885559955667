import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuth } from 'contexts/AuthProvider'
import { useNotification } from 'contexts/NotificationContext'

import Logo from 'assets/images/theprocess_text_logo.webp'

const Login = () => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { isAuthenticated, login } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated, navigate])

  const handleSubmit = (e) => {
    e.preventDefault()
  
    fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        if (!response.ok) {
          showNotification(t('error_login_failed'), 'error')
          throw new Error('error_login_failed')
        }
        return response.json()
      })
      .then((data) => {
        login(data.user)
        showNotification(t('login_ok'), 'success')
      })
      .catch(() => {
        showNotification(t('error_login_failed'), 'error')
      })
  }
  

  return (
    <div className='login'>
      <div className='login__form__container'>
        <img src={Logo} className='login__logo' alt='The Process Logo' />
        <form className='login__form' onSubmit={handleSubmit}>
          <input
            className='login__form__input'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('email')}
          />
          <input
            className='login__form__input'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('password')}
          />
          <button className='main-btn login__form__input__btn' type='submit'>{t('signin')}</button>
        </form>
      </div>
    </div>
  )
}

export default Login
