import React from 'react'
import { useTranslation } from 'react-i18next'

const Text = ({ disabled, handleChangeValue, placeholder, value }) => {
  const { t } = useTranslation('global')

  return (
    <div className='text'>
      <input
        className={`${disabled ? 'disabled' : ''} text__input`}
        onChange={(e) => handleChangeValue(e.target.value)}
        placeholder={placeholder || t('type')}
        value={value || ''}
      />
    </div>
  )
}

export default Text
