import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { useNotification } from 'contexts/NotificationContext'

import SearchFilters from 'features/SearchFilters'
import SearchCompaniesResults from 'features/SearchCompaniesResults'
import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'

const SearchCompanies = () => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const [newFilters, setNewFilters] = useState({})
  const [currentFilters, setCurrentFilters] = useState({})
  const [companiesResults, setCompaniesResults] = useState({companies: [], context: {}})

  const handleChangeFilters = (key, value) => {
    setNewFilters((prevNewFilters) => {
      const updatedFilters = { ...prevNewFilters }
  
      if (!value) {
        delete updatedFilters[key]
      } else {
        updatedFilters[key] = value
      }
  
      return updatedFilters
    })
  }

  const handleSubmitFilters = (page) => {
    setIsLoading(true)
    setCurrentFilters(newFilters)

    const filtersToFetch = Object.fromEntries(
      Object.entries(newFilters).map(([key, value]) => {
        if (Array.isArray(value) && value.every(item => item.value !== undefined)) {
          return [key, value.map(item => item.value)]
        } else if (value && typeof value === 'object' && 'value' in value) {
          return [key, value.value]
        } else {
          return [key, value]
        }
      })
    )
    apiClient
      .post(buildApiUrl(`/companies/search`, [], page), filtersToFetch)
      .then((response) => {
        setCompaniesResults(response.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleImportCompanies = (company_linkedin_ids) => {
    apiClient.post(buildApiUrl('/companies'), {'company_linkedin_ids': company_linkedin_ids})
      .then(() => {
        showNotification(t('companies_added', {count: company_linkedin_ids.length}) , 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }

  const handleCancelFilters = () => {
    setNewFilters(currentFilters)
  }

  const hasNewFilters =
    Object.keys(newFilters).length !== Object.keys(currentFilters).length || 
    Object.keys(newFilters).some((key) => newFilters[key] !== currentFilters[key])
  
  useEffect(() => {
    hasNewFilters && handleSubmitFilters()
  }, [])

  const companiesTotal = (companiesResults && companiesResults.context && companiesResults.context.total_count)

  return (
    <Layout>
      <Topbar
        description={t('search_companies_description')}
        icon={<FontAwesomeIcon icon={faCity} />}
        title={t('search_companies')}
        number={companiesTotal}
      />
      <Tabs
        activeTab={{name: t('new_search'), id: 0}}
        tabs={[{name: t('new_search'), id: 0}]}
      />
      <div className='companies'>
        <SearchFilters
          handleChangeFilters={handleChangeFilters}
          type='searchCompanies'
          newFilters={newFilters}
        />
        <SearchCompaniesResults
          companies={companiesResults.companies}
          searchContext={companiesResults.context}
          handleImportCompanies={handleImportCompanies}
          handleCancelFilters={handleCancelFilters}
          handleSubmitFilters={(page) => handleSubmitFilters(page)}
          hasNewFilters={hasNewFilters}
          isLoading={isLoading}
        />
      </div>
    </Layout>
  )
}

export default SearchCompanies
