import React, { createContext, useContext, useState } from 'react'
import Notification from 'components/Notification'

const NotificationContext = createContext()

export const useNotification = () => useContext(NotificationContext)

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({ status: '' })

  const showNotification = (message, status) => {
    setNotification({ message, status })

    setTimeout(() => {
      setNotification({ message: '', status: '' })
    }, 3000)
  }

  const hideNotification = () => setNotification({ message: '', status: '' })

  return (
    <NotificationContext.Provider value={{showNotification, hideNotification}} >
      {children}
      <Notification
        message={notification.message}
        status={notification.status}
        onClose={hideNotification}
      />
    </NotificationContext.Provider>
  )
}
