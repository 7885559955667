import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enGlobal from './locales/en/global.json'
import frGlobal from './locales/fr/global.json'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        global: enGlobal
      },
      fr: {
        global: frGlobal
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
