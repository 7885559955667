import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const FormSelect = ({
  choices = [],
  handleChangeValue,
  isEmoji = false,
  placeholder,
  value
}) => {
  const { t } = useTranslation('global')
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [filtredChoices, setFilteredChoices] = useState(choices)
  const selectRef = useRef(null)

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  const handleChangeSearch = (search) => {
    setSearch(search.toLowerCase())
  }

  const onChangeValue = (value) => {
    handleChangeValue(value)
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const filtered = choices.filter((choice) =>
      (t(choice.id).toLowerCase().includes(search.toLowerCase()) || choice.id.toLowerCase().includes(search.toLowerCase()))
    )
    setFilteredChoices(filtered)
  }, [choices, search, t])

  const toggleList = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={`form_select ${isEmoji ? 'is_emoji' : ''}`} ref={selectRef}>
      {!isOpen &&
        <div className='select__button' onClick={toggleList}>
          {value ?
            <span>{value}</span>
            :
            <span>{placeholder ? t(placeholder) : t('select')}</span>
          }
          <FontAwesomeIcon className='select__button__icon' icon={faChevronDown} />
        </div>
      }
      {isOpen &&
      <>
        <input
          className='select__button'
          value={search}
          onChange={(e) => handleChangeSearch(e.target.value)}
        />
        <ul className='select__list'>{filtredChoices.map(choice => <li key={choice.id} onClick={() => onChangeValue(choice.value)}>{t(choice.id)}</li>)}</ul>
      </>
      } 
    </div>
  )
}

export default FormSelect
