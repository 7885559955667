import React, { useEffect, useState } from 'react'
import useFormattedDate from 'hooks/useFormattedDate'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassHalf, faComments, faPaperPlane, faUserPlus, faAnglesDown, faInbox } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'
import { useNotification } from 'contexts/NotificationContext'

import Text from './Text'
import TextArea from './TextArea'

const ActionsSetup = ({ activeContact, activeSequence }) => {
  const { t } = useTranslation('global')
  const { showNotification } = useNotification()
  const formatDate = useFormattedDate()
  const [actions, setActions] = useState([])

  const fetchActions = () => {
    activeContact && activeSequence ?
      apiClient.get(buildApiUrl(`/sequences/${activeSequence.id}/actions`, {contact_id: activeContact.id}))
        .then((response) => {
          setActions(response.data)
        })
      :
      setActions([])
  }

  const handleChangeAction = (index, field, value) => {
    setActions((prevActions) => {
      const updatedActions = [...prevActions]
      updatedActions[index] = { ...updatedActions[index], [field]: value }
      return updatedActions
    })
  }

  const handleSaveActions = (action) => {
    if (!action) {
      showNotification(t('error_occured') , 'error')
      return
    }
  
    const isNewAction = !action.id
    const url = isNewAction
      ? buildApiUrl(`/steps/${action.step_id}/actions`)
      : buildApiUrl(`/actions/${action.id}`)
  
    const requestMethod = isNewAction
      ? apiClient.post(url, action)
      : apiClient.put(url, action)
  
    requestMethod
      .then((response) => {
        const updatedAction = response.data
  
        setActions((prevActions) => {
          if (isNewAction) {
            return [...prevActions, updatedAction]
          } else {
            return prevActions.map((existingAction) =>
              existingAction.id === updatedAction.id ? updatedAction : existingAction
            )
          }
        })
  
        showNotification(t('changes_saved_for_this_user') , 'success')
      })
      .catch(() => {
        showNotification(t('error_occured') , 'error')
      })
  }
  

  useEffect(() => {
    fetchActions()
  }, [activeContact, activeSequence])

  return (
    <div className='actions_setup'>
      {actions && actions.map((action, index) => {
        return (
          <div key={index} className='actions_setup__item'>
            <div className='actions_setup__header'>
              <div className='actions_setup__header__block'>
                <div className='actions_setup__header__from'>
                  <div className='actions_setup__header__from__infos'>
                    <img
                      className='actions_setup__header__from__img'
                      src={action.owner.picture_url} alt={action.owner.first_name + ' ' + action.owner.last_name}
                    />
                    <div>
                      <p className='actions_setup__header__from__name'>{action.owner.first_name + ' ' + action.owner.last_name}</p>
                      <p className='actions_setup__header__from__email'>{action.owner.email}</p>
                    </div>
                    <p></p>
                  </div>
                </div>
                {action.delay_days !== 0 &&
                <>
                  <div className='actions_setup__header__icon'>
                    <FontAwesomeIcon icon={faAnglesDown} />
                  </div>
                  <div className='actions_setup__item__infos'>
                    <div className='actions_setup__item__info'>
                      <FontAwesomeIcon icon={faHourglassHalf} />
                      <span>{t('wait')} {t(action.delay_days)} {t('days')}</span>
                    </div>
                  </div>
                </>
                }
                <div className='actions_setup__header__chevron'>
                  <FontAwesomeIcon icon={faAnglesDown} />
                </div>
                <div className='actions_setup__item__infos'>
                  <div className='actions_setup__item__info'>
                    {action.action_type === 'linkedin_message' &&
                      <FontAwesomeIcon className='actions_setup__header__icon' icon={faComments} />
                    }
                    {action.action_type === 'linkedin_invite' &&
                      <FontAwesomeIcon className='actions_setup__header__icon' icon={faUserPlus} />
                    }
                    {action.action_type === 'email' &&
                      <FontAwesomeIcon className='actions_setup__header__icon' icon={faPaperPlane} />
                    }
                    <span>{t(action.action_type)}</span>
                  </div>
                </div>
                <div className='actions_setup__header__chevron'>
                  <FontAwesomeIcon icon={faAnglesDown} />
                </div>
                <div className='actions_setup__header__from'>
                  <div className='actions_setup__header__from__infos'>
                    <img
                      className='actions_setup__header__from__img'
                      src={action.contact.picture_url} alt={action.contact.first_name + ' ' + action.contact.last_name}
                    />
                    <div>
                      <p className='actions_setup__header__from__name'>{action.contact.first_name + ' ' + action.contact.last_name}</p>
                      <p className='actions_setup__header__from__email'>{action.contact.email}</p>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className='actions_setup__header__block'>
                {action.events && action.events.length > 0 && action.events.map((event, index) => {
                  return (
                    <>
                      <div className='actions_setup__header__from'>
                        <div className='actions_setup__header__from__infos'>
                          {event.event_type === 'email_sent' && <FontAwesomeIcon className='actions_setup__header__icon' icon={faPaperPlane} />}
                          {event.event_type === 'email_received' && <FontAwesomeIcon className='actions_setup__header__icon' icon={faInbox} />}
                          <div>
                            <p className='actions_setup__header__from__name'>{t(event.event_type)}</p>
                            <p className='actions_setup__header__from__email'>{formatDate(event.occurred_at)}</p>
                          </div>
                          <p></p>
                        </div>
                      </div>
                      {index < action.events.length - 1 && 
                        <div className='actions_setup__header__chevron'>
                          <FontAwesomeIcon icon={faAnglesDown} />
                        </div>
                      }
                    </>
                  )
                })}
              </div>
            </div>
            {action.action_type === 'email' &&
              <>
                <p className='actions_setup__item__label'>{t('subject')}</p>
                <Text
                  disabled={action.status !== 'pending'}
                  handleChangeValue={(e) => handleChangeAction(index, 'subject', e.target.value)}
                  value={action.subject}
                />
              </>
            }
            {['email', 'linkedin_message', 'linkedin_invite'].includes(action.action_type) &&
            <>
              <p className='actions_setup__item__label'>{t('body')}</p>
              <TextArea
                disabled={action.status !== 'pending'}
                handleChangeValue={(newValue) => handleChangeAction(index, 'content', newValue)}
                value={action.content}
              />
            </>
            }
            {action.status === 'pending' &&
              <div className='actions_setup__item__cta__container'>
                <div className='main-btn' onClick={() => handleSaveActions(action)}>{t('save')}</div>
              </div>
            }
          </div>
        )
      })}
    </div>
  )
}

export default ActionsSetup
