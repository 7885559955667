import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

const ListSelector = ({lists, activeList, handleChangeActiveList, handleDetroyList, listModel}) => {
  const { t } = useTranslation('global')

  const companiesLists = lists && lists.length > 0 ? lists.filter(list => list.model === 'company') : []
  const contactsLists = lists && lists.length > 0 ? lists.filter(list => list.model === 'contact') : []
  const showContacts = listModel.id === 0 && contactsLists && contactsLists.length > 0
  const showCompanies = listModel.id === 1 && companiesLists && companiesLists.length > 0

  return (
    <div className='list_selector'>
      <div className='list_selector__header'>
        <div>{t('name')}</div>
        <div>{t('actions')}</div>
      </div>
      {showContacts &&
        contactsLists.map(list => {
          return (
            <div key={list.id} className={`list_selector__row ${activeList === list ? 'active' : ''}`}>
              <div>
                <span className='list_selector__row__icon'>{list.icon}</span>
                <span>{list.name}</span>
              </div>
              <div className='list_selector__ctas'>
                <div className='main-btn small' onClick={() => handleChangeActiveList(list)}><FontAwesomeIcon icon={faEye} /></div>
                <div className='remove-btn small' onClick={() => handleDetroyList(list)}><FontAwesomeIcon icon={faTrash} /></div>
              </div>
            </div>
          )
        })
      }
      {showCompanies &&
        companiesLists.map(list => {
          return (
            <div key={list.id} className={`list_selector__row ${activeList === list ? 'active' : ''}`}>
              <div
                key={list.id}
                onClick={() => handleChangeActiveList(list) }
              >
                {list.name}
              </div>
              <div className='list_selector__ctas'>
                <div className='main-btn small' onClick={() => handleChangeActiveList(list)}><FontAwesomeIcon icon={faEye} /></div>
                <div className='remove-btn small' onClick={() => handleDetroyList(list)}><FontAwesomeIcon icon={faTrash} /></div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ListSelector
