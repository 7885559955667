export const STATUS = [
  {value: "enriching", text: "enriching"},
  {value: "enriched", text: "enriched"},
  {value: "contacted", text: "contacted"},
  {value: "connected", text: "connected"},
  {value: "meeting_booked", text: "meeting_booked"},
  {value: "meeting_done", text: "meeting_done"},
  {value: "opportunity", text: "opportunity"},
  {value: "won", text: "won"},
  {value: "lost", text: "lost"},
]

export const LANGUAGES = [
  { value: "ar", text: "ar" },
  { value: "bg", text: "bg" },
  { value: "cs", text: "cs" },
  { value: "cy", text: "cy" },
  { value: "da", text: "da" },
  { value: "de", text: "de" },
  { value: "el", text: "el" },
  { value: "en", text: "en" },
  { value: "es", text: "es" },
  { value: "et", text: "et" },
  { value: "eu", text: "eu" },
  { value: "fa", text: "fa" },
  { value: "fi", text: "fi" },
  { value: "fr", text: "fr" },
  { value: "ga", text: "ga" },
  { value: "gl", text: "gl" },
  { value: "he", text: "he" },
  { value: "hi", text: "hi" },
  { value: "hr", text: "hr" },
  { value: "hu", text: "hu" },
  { value: "id", text: "id" },
  { value: "is", text: "is" },
  { value: "it", text: "it" },
  { value: "ja", text: "ja" },
  { value: "ko", text: "ko" },
  { value: "lt", text: "lt" },
  { value: "lv", text: "lv" },
  { value: "ms", text: "ms" },
  { value: "mt", text: "mt" },
  { value: "nl", text: "nl" },
  { value: "no", text: "no" },
  { value: "pl", text: "pl" },
  { value: "pt", text: "pt" },
  { value: "ro", text: "ro" },
  { value: "ru", text: "ru" },
  { value: "sk", text: "sk" },
  { value: "sl", text: "sl" },
  { value: "sr", text: "sr" },
  { value: "sv", text: "sv" },
  { value: "th", text: "th" },
  { value: "tr", text: "tr" },
  { value: "uk", text: "uk" },
  { value: "vi", text: "vi" },
  { value: "zh", text: "zh" }
]

export const COMPANY_SIZES_MIN = [
  {value: "1", text: "1"},
  {value: "11", text: "11"},
  {value: "51", text: "51"},
  {value: "201", text: "201"},
  {value: "501", text: "501"},
  {value: "1001", text: "1001"},
  {value: "5001", text: "5001"},
  {value: "10001", text: "10001"},
]

export const COMPANY_SIZES_MAX = [
  {value: "10", text: "10"},
  {value: "50", text: "50"},
  {value: "200", text: "200"},
  {value: "500", text: "500"},
  {value: "1000", text: "1000"},
  {value: "5000", text: "5000"},
  {value: "10000", text: "10000"},
]

export const TENURE_MIN = [
  {value: "0", text: "0"},
  {value: "1", text: "1"},
  {value: "3", text: "3"},
  {value: "6", text: "6"},
  {value: "10", text: "10"}
]

export const TENURE_MAX = [
  {value: "1", text: "1"},
  {value: "2", text: "2"},
  {value: "5", text: "5"},
  {value: "10", text: "10"}
]

export const ICONS = [
  { value: "🚀", text: "🚀" },
  { value: "📞", text: "📞" },
  { value: "✉️", text: "✉️" },
  { value: "💡", text: "💡" },
  { value: "📈", text: "📈" },
  { value: "🌟", text: "🌟" },
  { value: "🤝", text: "🤝" },
  { value: "🎯", text: "🎯" },
  { value: "📆", text: "📆" },
  { value: "⚡", text: "⚡" },
  { value: "💼", text: "💼" },
  { value: "🔥", text: "🔥" },
  { value: "🌐", text: "🌐" },
  { value: "🔑", text: "🔑" },
  { value: "🧩", text: "🧩" },
  { value: "📢", text: "📢" },
  { value: "🏆", text: "🏆" },
  { value: "⏳", text: "⏳" },
  { value: "👥", text: "👥" },
  { value: "💬", text: "💬" }
]

export const SENIORITIES = [
  {value: "owner_partner", text: "Owner / Partner"},
  {value: "cxo", text: "CXO"},
  {value: "vice_president", text: "Vice President"},
  {value: "director", text: "Director"},
  {value: "experienced_manager", text: "Experienced Manager"},
  {value: "entry_level_manager", text: "Entry Level Manager"},
  {value: "strategic", text: "Strategic"},
  {value: "senior", text: "Senior"},
  {value: "entry_level", text: "Entry Level"},
  {value: "in_training", text: "In Training"},
]

export const COMPANY_TYPES = [
  {value: 'public_company', text: 'Public Company'},
  {value: 'privately_held', text: 'Privately Held'},
  {value: 'non_profit', text: 'Non Profit'},
  {value: 'educational_institution', text: 'Educational Institution'},
  {value: 'partnership', text: 'Partnership'},
  {value: 'self_employed', text: 'Self Employed'},
  {value: 'self_owned', text: 'Self Owned'},
  {value: 'government_agency', text: 'Government Agency'},
]

export const PARAMETER_MAPPING = {
  company_industry: 'INDUSTRY',
  department: 'DEPARTMENT',
  department_headcount_name: 'DEPARTMENT',
  location: 'LOCATION',
  company_location: 'LOCATION',
  company: 'COMPANY',
  job_title: 'JOB_TITLE',
  school: 'SCHOOL',
  technologies: 'TECHNOLOGIES'
}

export const PROGRESS = [
  { value: "0", text: "0" },
  { value: "10", text: "10" },
  { value: "20", text: "20" },
  { value: "30", text: "30" },
  { value: "40", text: "40" },
  { value: "50", text: "50" },
  { value: "60", text: "60" },
  { value: "70", text: "70" },
  { value: "80", text: "80" },
  { value: "90", text: "90" },
  { value: "100", text: "100" },
]

export const FOLLOWERS_COUNT_MIN = [
  {value: "1", text: "1"},
  {value: "51", text: "51"},
  {value: "101", text: "101"},
  {value: "1001", text: "1001"},
  {value: "5001", text: "5001"}
]


export const FOLLOWERS_COUNT_MAX = [
  {value: "50", text: "50"},
  {value: "100", text: "100"},
  {value: "1000", text: "1000"},
  {value: "5000", text: "5000"}
]
